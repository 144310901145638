import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssetDataDocument,
  ERC20MixerDocument,
  ERC20TokenDocument,
  FinalizedConfigurationDocument,
  IdentityDocument,
  RegistredIdentityDocument,
} from '@api/models';
import { PopulatedAssetDataDocument } from '../../types/populated-asset-data-document';
import { AUTHORIZATION_REQUIRED } from '../http/http-context-tokens';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getConfiguration() {
    return this.http.get<Array<FinalizedConfigurationDocument>>(
      'configuration'
    );
  }

  listAssets(address: string) {
    return this.http.get<Array<PopulatedAssetDataDocument>>(`list-assets`, {
      params: new HttpParams().set('address', address),
    });
  }

  getAsset(id: string) {
    return this.http.get<AssetDataDocument>(`get-asset/${id}`);
  }

  saveAsset(asset: AssetDataDocument) {
    return this.http.post<AssetDataDocument>(`save-asset`, asset, {
      context: new HttpContext().set(AUTHORIZATION_REQUIRED, true),
    });
  }

  authSignature(signature: string, signedTimestamp: number) {
    return this.http.post<{ token: string }>(`auth/signature`, {
      signature,
      signedTimestamp,
    });
  }

  saveErc20Token(erc20SaveRequest: {
    assetData: string;
    transactionHash: string;
    chainId: number;
  }) {
    return this.http.post<ERC20TokenDocument>(
      `erc-20-token/save`,
      erc20SaveRequest,
      {
        context: new HttpContext().set(AUTHORIZATION_REQUIRED, true),
      }
    );
  }

  getIdentity(wallet: string, chainId: number) {
    return this.http.get<IdentityDocument | null>(
      `identity/${wallet}/${chainId}`,
      {
        context: new HttpContext().set(AUTHORIZATION_REQUIRED, true),
      }
    );
  }

  listRegistredIdentities(trexSuiteId: string) {
    return this.http.get<Array<RegistredIdentityDocument>>(
      `registred-identity/${trexSuiteId}`
    );
  }

  createErc20Mixer(erc20MixerCreateRequest: {
    chainId: number;
    transactionHash: string;
    icon: string;
  }) {
    return this.http.post<ERC20MixerDocument>(
      'erc-20-mixer/create',
      erc20MixerCreateRequest
    );
  }

  listErc20Mixers() {
    return this.http.get<ERC20MixerDocument[]>('erc-20-mixer/list');
  }

  getErc20Mixer(id: string) {
    return this.http.get<ERC20MixerDocument>(`erc-20-mixer/get/${id}`);
  }
}
